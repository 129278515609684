body {
  @extend %font-light;
  font-size: 16px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

strong {
  @extend %font-bold;
  font-size: inherit;
}

a {
  color: #000;

  &:hover, &:focus, &:active, &:visited {
    color: #000;
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-size: 1.25rem;
  line-height: 1rem;
  font-weight: 500;

  @include media-breakpoint-up(lg) {
    font-size: 1.375rem;
    line-height: 1.125rem;
  }
}

p {
  font-size: 0.8125rem;
  line-height: 1rem;

  @include media-breakpoint-up(lg) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }
}