body.page {
  background-color: $bg-color-light-blue;
}

#main-content {
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: $content-width;

  transition: margin-top .4s ease-in-out;

  .page-content {
    padding: 28px 70px 54px 23px;

    @include media-breakpoint-up(lg) {
      padding-left: 96px;
      padding-right: 93px;
    }

    a, a:hover, a:active, a:focus, a:visited {
      color: $color-green;
      text-decoration: underline;
    }
  }
}
