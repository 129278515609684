#main-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
  transform: translateY(-100%);
  transition: transform .4s ease-in-out;

  .header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-left: auto;
    margin-right: auto;
    max-width: $content-width;

    padding-top: 28px;
    padding-left: 23px;
    padding-right: 23px;

    @include media-breakpoint-up(lg) {
      padding-left: 96px;
      padding-right: 93px;
    }
  }

  .logo {
    width: 163px;
    height: 31px;

    @include media-breakpoint-up(lg) {
      width: 230px;
      height: 43px;
    }

    img {
      width: 100%;

      @include media-breakpoint-up(lg) {
        margin-top: 3px;
      }
    }
  }

  nav {
    $nav-button-width: 50px;
    $nav-button-spacing: 8px;

    @include media-breakpoint-up(lg) {
      order: 4;
    }

    .nav-button {
      display: block;
      width: $nav-button-width;
      padding: 5px;
      cursor: pointer;

      i {
        display: block;
        height: 2px;
        background-color: black;
        opacity: 1;
        transition: transform .2s ease-in-out, opacity .2s ease-in-out;
        margin-bottom: $nav-button-spacing;

        &:nth-child(1) {
        }
        &:nth-child(2) {
        }
        &:nth-child(3) {
          margin-bottom: 0;
        }
      }
    }
    &.open .nav-button {
      i:nth-child(1) {
        transform: rotate(-45deg) translate(-7px, 7px);
      }

      i:nth-child(2) {
        opacity: 0;
      }

      i:nth-child(3) {
        transform: rotate(45deg) translate(-7px, -7px);
      }
    }

    .nav-items {
      position: absolute;
      top: 92px;
      left: 0;
      width: 100%;
      padding-left: 22px;
      padding-bottom: 10px;
      background-color: #fff;
      box-shadow: 0 -2px 19px 0 rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      z-index: 50;

      &:before {
        content: '';
        display: block;
        height: 21px;
        width: 100%;
        background-color: white;
        position: absolute;
        left: 0;
        top: -20px;
      }

      .nav-items-content {
        margin-left: auto;
        margin-right: auto;
        max-width: $content-width;

        @include media-breakpoint-up(lg) {
          display: flex;
          justify-content: space-between;
          padding-left: 106px;
          padding-right: 139px;
          padding-top: 30px;
          margin-bottom: 52px;

          font-size: 1.25rem;
        }
      }

      .social-icons {
        position: absolute;
        right: 32px;
        bottom: 36px;
      }
    }
    &.open .nav-items {
      opacity: 1;
      visibility: visible;
    }

    .item {
      position: relative;
      border-top: 1px solid #D8D8D8;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-left: 44px;
      width: 200px;
      font-size: 1.125rem;
      line-height: 1.625rem;

      &:before {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        left: 4px;
        background: no-repeat center center;
        background-size: contain;
      }

      &.acalendar:before {
        background-image: url(#{$path-images}/icon_acalendar.png);
      }
      &.aclock:before {
        background-image: url(#{$path-images}/icon_aclock.png);
      }
      &.tapir:before {
        background-image: url(#{$path-images}/icon_tapir.png);
      }
    }
  }

  .header-text {
    margin-top: 19px;
    margin-bottom: 24px;
    width: 100%;
    line-height: 1.25rem;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 40%;
    }

    p {
      font-size: 1.125rem;
      line-height: 1.375rem;

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .social-icons {
    font-size: 1.5rem;

    @include media-breakpoint-up(lg) {
      order: 3;
      li {
        display: inline-block;
      }
    }

    li {
      margin-bottom: 11px;

      a {
        display: block;

        i {
          color: #5AAAAA;
          display: block;
        }
      }
    }
  }
}