$grid-breakpoints: (
        xs: 0, // Extra small screen / phone
        sm: 576px, // Small screen / phone
        md: 768px, // Medium screen / tablet
        lg: 992px, // Large screen / desktop
        xl: 1280px, // Extra large screen / wide desktop
);

$grid-gutter-width: 30px;
$grid-columns: 24;

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// );

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities/flex";
//@import "node_modules/bootstrap/scss/utilities/text";
@import "node_modules/bootstrap/scss/utilities/display";
//@import "node_modules/bootstrap/scss/utilities/screenreaders";
@import "node_modules/bootstrap/scss/utilities/spacing";
