body.page.page-home,
body.page.page-home-single {
  background-color: $bg-color-green;

  $button-width: 512px;
  $button-height: 153px;
  $button-scale: .215686275;
  $button-scale-md: .294117647;

  #main-content {
    background-color: rgba(255, 255, 255, 0.5);

    .page-content {
      padding: 18px;

      @include media-breakpoint-up(md) {
        padding: 96px 130px;
      }
    }

    .app {
      margin-top: 62px;
      margin-bottom: 50px;

      &.app-acalendar {
        .meta .icon {
          background-image: url("#{$path-images}/icon_acalendar.png");
        }
        .button {
          color: $color-blue;
          border-color: $color-blue;
          i {
            border-color: $color-blue;
            background-image: url("#{$path-images}/button_support-acalendar.png");
          }
        }
        .coming-soon {
          color: $color-blue;
        }
      }

      &.app-aclock {
        .meta .icon {
          background-image: url("#{$path-images}/icon_aclock.png");
        }
        .button {
          color: $color-orange;
          border-color: $color-orange;
          i {
            border-color: $color-orange;
            background-image: url("#{$path-images}/button_support-aclock.png");
          }
        }
        .coming-soon {
          color: $color-orange;
          border-color: $color-orange;
        }
      }

      .meta {
        @include clearfix;
        margin-bottom: 20px;

        .icon {
          display: inline-block;
          vertical-align: middle;
          width: 110px;
          height: 110px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .description {
          margin-left: 18px;
          display: inline-block;
          vertical-align: middle;
          font-size: 0.9375rem;
          line-height: 1.125rem;

          @include media-breakpoint-up(md) {
            margin-left: 34px;
            font-size: 1.125rem;
            line-height: 1.25rem;
          }

          h2 {
            font-size: 1.25rem;
            line-height: 1.5rem;

            @include media-breakpoint-up(md) {
              font-size: 1.5rem;
              line-height: 1.75rem;
            }
          }
        }
      }

      ul.buttons {
        @include clearfix;

        li {
          float: left;
          margin-right: 20px;
        }
      }

      .button {
        @extend %font-bold;
        position: relative;
        display: block;
        width: $button-width * $button-scale;
        height: $button-height * $button-scale;
        text-decoration: none;

        background-color: #fff;
        border: 1px solid #000;
        border-radius: 6px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.0);
        transition: box-shadow .1s ease-in-out;

        @include media-breakpoint-up(md) {
          width: $button-width * $button-scale-md;
          height: $button-height * $button-scale-md;
        }

        &:hover, &:active, &:focus {
          box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
        }

        i {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 36px;
          border-right: 1px solid black;
          background: url("#{$path-images}/button_support.png") no-repeat center center;
          background-size: 70% auto;
          @include media-breakpoint-up(md) {
            width: 45px;
          }
        }

        span {
          display: block;
          line-height: $button-height * $button-scale;
          margin-left: 28%;
          padding-left: 13px;
          font-size: 0.95rem;
         // margin-top: -1px;

          @include media-breakpoint-up(md) {
            line-height: $button-height * $button-scale-md;
            font-size: 1.25rem;
          }
        }
      }

      .support-button {
        width: auto;
        min-width: $button-width * $button-scale;

        @include media-breakpoint-up(md) {
          min-width: $button-width * $button-scale-md;
        }
      }

      .google-play, .apple-store {
        background: transparent url("#{$path-images}/button_google-play-badge.png") no-repeat center center;
        background-size: 100% 100%;
        border: none;
        //border-radius: initial;
      }

      .coming-soon {
        @extend %font-bold;
        font-size: 0.95rem;
        line-height: $button-height * $button-scale - 2px;
        cursor: default;

        display: inline-block;
        background-color: #fff;
        border: 1px solid black;
        border-radius: 6px;
        padding: 0 10px;

        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
          line-height: $button-height * $button-scale-md;
        }
      }
    }

    .app-list {
      @include clearfix;

      .app {
        float: left;
        margin-top: 0;
        margin-bottom: 20px;
        margin-right: 20px;

        @include media-breakpoint-up(md) {
          margin-right: 100px;
          margin-bottom: 50px;
        }

        &:last-child {
          margin-right: 0;
        }

        .icon {
          display: block;
          width: 64px;
          height: 64px;
          margin-bottom: 10px;

          @include media-breakpoint-up(md) {
            display: inline-block;
            width: 110px;
            height: 110px;
          }
        }

        .description {
          margin-left: 0;
          display: block;
          font-size: 0.875rem;
          line-height: 1rem;

          @include media-breakpoint-up(md) {
            margin-left: 30px;
            display: inline-block;
            font-size: 1.125rem;
            line-height: 1.25rem;
          }

          h2 {
            @include media-breakpoint-up(md) {
              font-size: 1.5rem;
              line-height: 1.75rem;
            }
          }
        }

        ul.buttons {
          li {
            float: none;
            margin-bottom: 12px;

            &:last-child {
              margin-right: 0;
            }

            @include media-breakpoint-up(md) {
              float: left;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
