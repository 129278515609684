#main-content {
  position: relative;
  z-index: 0;

  %tapir {
    width: 100%;
    background: transparent url('#{$path-images}/bg_tapir.png') no-repeat right bottom;
    background-size: contain;
    z-index: 25;

    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: transparent url('#{$path-images}/bg_tapir-eyelid.png') no-repeat right bottom;
      background-size: contain;

      animation: tapir-eyelid 40000ms cubic-bezier(1, -1, 0, 2) infinite;

      @keyframes tapir-eyelid {
        0% { opacity: 0; } // open

        30% { opacity: 0; } // open
        30.2% { opacity: 1; } // closed
        30.4% { opacity: 0; } // open

        70% { opacity: 0; } // open
        70.2% { opacity: 1; } // closed
        70.4% { opacity: 0; } // open

        100% { opacity: 0; } // open
      }
    }
  }

  .tapir-background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    opacity: 0;
    transition: opacity .4s ease-in-out;

    .layer {
      position: absolute;
    }

    &.tapir-in-the-jungle {
      .water {
        bottom: 0;
        width: 100%;
        height: 22%;
        background-color: #5AAAAA;
        z-index: 10;
      }
      .jungle {
        top: 0;
        right: 0;
        width: 100%;
        height: 80%;
        background: #D7E5D8 url('#{$path-images}/bg_jungle.png') no-repeat right bottom;
        background-size: cover;
      }
      .leaf-0 {
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: transparent url('#{$path-images}/bg_leaf-0.png') no-repeat right bottom;
        background-size: contain;
        z-index: 20;
      }
      .leaf-1 {
        height: 30%;
        top: 50%;
        right: 0;
        z-index: 30;

        animation: swing-leaf-1 ease-in-out 7s infinite alternate;
        transform: rotate(3deg) translate(50%, -50%);
        transform-origin: 94.855305466% 96.428571429%;

        @keyframes swing-leaf-1 {
          0% {
            transform: rotate(1deg) translate(50%, -50%);
          }
          100% {
            transform: rotate(-5deg) translate(50%, -50%);
          }
        }
      }
      .leaf-2 {
        height: 25%;
        top: -5px;
        right: 40px;
        z-index: 30;

        animation: swing-leaf-2 ease-in-out 3s infinite alternate;
        transform-origin: 37.356321839% 0;

        @keyframes swing-leaf-2 {
          0% {
            transform: rotate(-3deg);
          }
          100% {
            transform: rotate(8deg);
          }
        }
      }
      .leaf-3 {
        height: 50%;
        bottom: 0;
        right: 0;
        z-index: 40;

        animation: swing-leaf-3 ease-in-out 6s infinite alternate;
        transform-origin: right bottom;

        @keyframes swing-leaf-3 {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(1deg);
          }
        }
      }
      .tapir {
        @extend %tapir;

        right: 50%;
        bottom: 5%;
        height: 30%;
      }
    }

    &.tapir-in-the-wild {
      .tapir {
        @extend %tapir;
        position: fixed;

        right: -100px;
        bottom: 0;
        margin-bottom: 60px;
        height: 128px;

        // flip
        transform: scaleX(-1);
        background-position-x: left;
        &:before {
          background-position-x: left;
        }

        @include media-breakpoint-up(lg) {
          height: 150px;
          right: -128px;
          margin-bottom: 84px;
        }
      }

      &.reverse .tapir {
        right: -110px;

        // reset flip
        transform: none;
        background-position-x: right;
        &:before {
          background-position-x: right;
        }

        @include media-breakpoint-up(lg) {
          right: -140px;
        }
      }
    }
  }
  .page-content {
    position: relative;
    z-index: 100;
  }
}