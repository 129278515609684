
#main-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 19px 0 rgba(0, 0, 0, 0.5);
  transform: translateY(100%);
  transition: transform .4s ease-in-out;
  z-index: 110;

  background: #fff url("#{$path-images}/sloth.png") no-repeat right -6px;
  background-size: 150px;

  .handle {
    position: relative;
    text-align: center;
    padding-top: 32px;
    padding-bottom: 12px;
    cursor: pointer;

    margin-left: auto;
    margin-right: auto;
    max-width: $content-width;

    @include media-breakpoint-up(lg) {
      text-align: left;
      font-size: 1.25rem;
      padding-bottom: 32px;
      padding-left: 96px;
    }

    &:before {
      position: absolute;
      width: 32px;
      height: 10.5px;
      content: '';
      top: 13px;
      left: 50%;
      transform: rotate(180deg) translateX(50%);
      background: url(#{$path-images}/arrow.png) no-repeat;
      background-size: 100% 100%;

      @include media-breakpoint-up(lg) {
        background: url(#{$path-images}/arrow-2.png) no-repeat;
        width: 48px;
        height: 15px;
        top: 35px;
      }
    }

    .handle-text {
      opacity: 1;
      transition: opacity .2s ease-in-out;
    }
  }
  &.open .handle {
    &:before {
      transform: rotate(0deg) translateX(-50%);
    }

    .handle-text {
      opacity: 0;
    }
  }

  .scrollable-content {
    overflow-y: auto;
    margin-top: -14px;
    padding-left: 18px;
    padding-right: 18px;
    opacity: 0;
    transition: opacity .2s ease-in-out;

    margin-left: auto;
    margin-right: auto;
    max-width: $content-width;

    @include media-breakpoint-up(lg) {
      padding-left: 96px;
      padding-right: 10%;
    }

    > p {
      width: 58%;
	  height: 200px;
      font-size: 1rem;
      line-height: 1.25rem;

      @include media-breakpoint-up(lg) {
        width: 350px;
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }

    ul.care-list {
      margin-left: -9px;
      margin-right: -9px;

      @include media-breakpoint-up(sm) {
        width: 70%;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      li {
        @include clearfix;
        margin-bottom: 15px;
        padding: 9px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 6px;

        @include media-breakpoint-up(lg) {
          width: 43%;
          margin-right: 7%;
        }

        img {
          width: 98px;
          float: left;

          @include media-breakpoint-up(lg) {
            width: 120px;
          }
        }
        div {
          font-size: 0.875rem;
          line-height: 1rem;
          padding-left: 120px;

          @include media-breakpoint-up(lg) {
            line-height: 1.25rem;
            padding-left: 144px;
          }
        }
        a {
          font-weight: 400;
          color: $color-green;
        }
      }
    }
  }
  &.open .scrollable-content {
    opacity: 1;
  }
}
