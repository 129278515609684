@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

%font-general {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
}

%font-light {
  @extend %font-general;
  font-weight: 300;
}

%font-bold {
  @extend %font-general;
  font-weight: 500;
}