$color-green: #3EBA58;
$color-blue: #1B78B1;
$color-orange: #FF8C00;

$bg-color-sand: #F6F1E9;
$bg-color-green: #57A39B;
$bg-color-light-blue: #DFE8F8;

$content-width: 1280px;
$footer-height: 62px;

$path-images: '../images';
